.root {
  align-items: center;
  display: flex;
  flex: 1;
  width: 100%;
}

.wrapper {
  align-items: center;
  display: flex;
  border-radius: 8px;
  border: 1px solid var(--chakra-colors-whiteOpacity-6);
  padding: 0 12px;
  width: 100%;
  background-color: var(--chakra-colors-neutral-700);
  height: 44px;

  input {
    flex: 1;
    width: 100%;
    background-color: var(--chakra-colors-neutral-700);
  }
}

.iconWrapper {
  margin-left: 8px;
  margin-right: 8px;
  color: var(--chakra-colors-neutral-300);
}
