.root {
  //--flexy-primary: var(--chakra-colors-brand-primary);
  --flexy-white: var(--chakra-colors-neutral-200);

  :global(.flexy) {
    width: 100%;

    p {
      color: var(--chakra-colors-neutral-300);
    }

    > div {
      width: 100%;

      > div {
        width: 100%;
        transform: none !important;
      }
    }
  }
}
